/* eslint-disable */

import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  GeoJSON,
} from "react-leaflet";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import "./App.css";
import localGeoJSON from "./geo.json";

// Fix for the marker icon path
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface FeatureProperties {
  name: string;
  count?: number;
}

interface Item {
  Name: string;
  Timezone: string;
  Country: string;
  City: string;
  Lat: number;
  Long: number;
}

// interface IPData {
//   ip_as: string;
//   count: number;
// }

function App() {
  const [items, setItems] = useState<Item[]>([]);
  // const [ipData, setIpData] = useState<IPData[]>([]);
  const [geoJSONData, setGeoJSONData] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [geoJSONKey, setGeoJSONKey] = useState<number>(0); // New state for the GeoJSON component's key
  const [showMarkers] = useState(false); // New state for toggling markers
  const [maxCount, setMaxCount] = useState<number>(0);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const pointInPolygon = (point: [number, number], vs: [number, number][]) => {
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i][0],
        yi = vs[i][1];
      const xj = vs[j][0],
        yj = vs[j][1];
      const intersect =
        yi > point[1] !== yj > point[1] &&
        point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }
    return inside;
  };

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await fetch(
          `https://api-xb2cbucfja-uc.a.run.app/get_15m_report?page=${currentPage}&count=50`
        );
        const data = await response.json();
        setItems(data.items);

        const geoData = localGeoJSON;

        geoData.features.forEach((country: any) => {
          country.properties.count = 0; // reset the count

          data.items.forEach((item: Item) => {
            if (item.Country === country.properties.name) {
              country.properties.count++;
            } else if (
              country.geometry.coordinates.some((geometry: any) => {
                return pointInPolygon([item.Long, item.Lat], geometry[0]);
              })
            ) {
              country.properties.count++;
            }
          });
        });

        const calculatedMaxCount = Math.max(
          ...geoData.features.map(
            (f: { properties: FeatureProperties }) => f.properties.count || 0
          )
        );
        setMaxCount(calculatedMaxCount);

        setGeoJSONData(geoData);
        setGeoJSONKey((prevKey) => prevKey + 1); // Update the key for the GeoJSON component

        // Update to next page or loop back to the first page
        setCurrentPage((prevPage) => {
          return prevPage < data.pages - 1 ? prevPage + 1 : 0;
        });
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };

    fetchMapData();
  }, [currentPage]);

  // useEffect(() => {
  //   const fetchSummaryData = async () => {
  //     try {
  //       const response = await fetch(`https://api-xb2cbucfja-uc.a.run.app/get_summary?data_id=hourly`);
  //       const data = await response.json();
  //       if (data && data.summary && data.summary.count_by_as) {
  //         setIpData(data.summary.count_by_as.slice(0, 100));
  //       } else {
  //         console.error("Unexpected data structure:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching summary data:", error);
  //     }
  //   };

  //   fetchSummaryData();
  //   const summaryInterval = setInterval(fetchSummaryData, 3600000);
  //   return () => {
  //     clearInterval(summaryInterval);
  //   };
  // }, []);

  return (
    <div className="App">
      {/* Toggle switch for markers */}
      {/* <div style={{ padding: '10px' }}>
        <label>
          <input
            type="checkbox"
            checked={showMarkers}
            onChange={() => setShowMarkers(!showMarkers)}
          />
          Show Markers
        </label>
      </div> */}

      <div className="title-container">
          <h1 className="app-title">Kudurru</h1>
          <p className="app-description">AI defense network</p>
       </div>
       <MapContainer
        center={[20, 0]}
        zoom={2}
        style={{ height: "800px", width: "1000px" }}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {geoJSONData && (
          <GeoJSON
            key={geoJSONKey}
            data={geoJSONData}
            style={(feature: any) => {
              if (feature.properties.count === 0) {
                return {
                  fillColor: "gray",
                  weight: 1,
                  opacity: 1,
                  color: "white",
                  fillOpacity: 0.7,
                };
              }
              const greenChannel =
                165 - Math.round((165 * feature.properties.count) / maxCount);
              return {
                fillColor: `rgb(255, ${greenChannel}, 0)`,
                weight: 1,
                opacity: 1,
                color: "white",
                fillOpacity: 0.7,
              };
            }}
          />
        )}
        {showMarkers &&
          items.map(
            (
              item,
              index // Conditional rendering based on showMarkers state
            ) => (
              <Marker
                key={index}
                position={[item.Lat, item.Long]}
                icon={new Icon.Default()}
              >
                <Tooltip permanent>{item.Name}</Tooltip>
              </Marker>
            )
          )}
      </MapContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: `<stripe-buy-button 
                     buy-button-id="buy_btn_1NxJLPCS5uRPCJv2vTsCPe9y"
                     publishable-key="pk_live_51NxJ5VCS5uRPCJv20mICHwMG1NhpAYMag8SujPqtjfl7NLcS7Re9BSXlAyLsfpgSls2TAgMbvVuPZdxubm2eJyjD00PY0b3QE0">
                   </stripe-buy-button>`,
        }}
      />
      <a href="https://billing.stripe.com/p/login/dR69CoedffVy7HW3cc">
        Manage your subscription here.
      </a>

      {/* <table>
        <thead>
          <tr>
            <th>IP Address Name</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {ipData.map((data, index) => (
            <tr key={index}>
              <td>{data.ip_as}</td>
              <td>{data.count}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default App;
